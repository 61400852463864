import React, { useState } from "react";
import { Button, Stack, Typography, Box } from "@mui/material";
import { useCurrentUser } from "../../components/context/UserContext";
import { register, login, logout } from "../../helpers/authentication-helpers";
import { DxTextField } from "../../components/forms";

const AuthenticationForm = () => {
  const [registrationToggle, setRegistrationToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const { currentUser } = useCurrentUser();

  const submitRegistration = (event) => {
    event.preventDefault();
    register(event, formData, setFormData, setErrorMessage)
      .then(() => {
        login(event, formData, setFormData, setErrorMessage);
      })
      .catch((error) => {
        console.error("Registration error:", error);
      });
  };

  const submitLogin = (event) => {
    event.preventDefault();
    login(event, formData, setFormData, setErrorMessage);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "400px" },
          borderRadius: 2,
          boxShadow: 3,
          padding: 3,
          backgroundColor: "background.dark",
        }}
      >
        {!currentUser ? (
          <form
            onSubmit={registrationToggle ? submitRegistration : submitLogin}
            style={{
              width: "100%",
            }}
          >
            <Stack spacing={2}>
              {registrationToggle && (
                <DxTextField
                  type="text"
                  name="username"
                  label="Nombre de usuario"
                  value={formData.username}
                  handleChange={handleChange}
                  required
                />
              )}

              <DxTextField
                type="email"
                name="email"
                label="Correo electrónico"
                value={formData.email}
                handleChange={handleChange}
                required
              />
              <DxTextField
                type="password"
                name="password"
                label="Contraseña"
                value={formData.password}
                handleChange={handleChange}
                required
              />
            </Stack>
            {errorMessage && (
              <Typography
                variant="body2"
                sx={{
                  color: "error.main",
                  marginTop: 2,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {errorMessage}
              </Typography>
            )}
            <Stack
              direction="column"
              spacing={2}
              sx={{
                marginTop: 3,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  paddingX: 1.5,
                }}
              >
                {registrationToggle ? "Registrarme" : "Ingresar"}
              </Button>
              <Button
                variant="text"
                onClick={() => setRegistrationToggle(!registrationToggle)}
                sx={{
                  width: "100%",
                  textAlign: "center",
                  color: "text.primary",
                }}
              >
                {registrationToggle
                  ? "¿Ya tienes cuenta? Ingresa acá"
                  : "¿Primera vez? ¡Crea una cuenta!"}
              </Button>
            </Stack>
          </form>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6">
              Bienvenido nuevamente {currentUser.username} ❤️
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href="/raw-query"
              sx={{
                marginTop: 1,
              }}
            >
              Probar SmartDx
            </Button>
            <Button
              onClick={logout}
              variant="outlined"
              color="primary"
              sx={{
                marginTop: 1,
              }}
            >
              Cerrar sesión
            </Button>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default AuthenticationForm;
