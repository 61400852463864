import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import DxChip from "../chips/DxChip";

const KeyWordSelectedGrid = ({
  selected,
  setSelected,
  title,
  type,
  setSelectedType,
  selectedType,
  paramInfo: { name: paramName, type: paramType },
}) => {
  const getWeightValues = useCallback(
    (paramName) =>
      paramName === "antecedentes" ? [0, 0.5, 1, 2, 3] : [0.5, 1, 2, 3],
    []
  );

  const handleRemove = useCallback(
    (key) => {
      const newSelected = { ...selected };
      delete newSelected[key];
      setSelected(newSelected, paramName, paramType);
    },
    [selected, paramName, paramType, setSelected]
  );

  const handleChangeWeight = useCallback(
    (key) => {
      const newSelected = { ...selected };
      const currentWeight = newSelected[key];
      const weightValues = getWeightValues(paramName);
      const nextIndex =
        (weightValues.indexOf(currentWeight) + 1) % weightValues.length;
      newSelected[key] = weightValues[nextIndex];
      setSelected(newSelected, paramName, paramType);
    },
    [selected, paramName, paramType, setSelected, getWeightValues]
  );

  const getColorBasedOnWeight = useCallback((weight) => {
    switch (weight) {
      case 0:
        return "#FFFFFF";
      case 0.5:
        return "#b2dfdb";
      case 1:
        return "#F1F73E";
      case 2:
        return "#F17D4E";
      case 3:
        return "#F34D67";
      default:
        return "#b2dfdb";
    }
  }, []);

  const getImportanceBasedOnWeight = useCallback((weight) => {
    switch (weight) {
      case 0:
        return "-";
      case 0.5:
        return "!";
      case 1:
        return "!!";
      case 2:
        return "!!!";
      case 3:
        return "!!!!";
      default:
        return "!!";
    }
  }, []);

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{
        width: "100%",
        borderRadius: "6px",
        overflow: "hidden", // Asegura que el contenido respete los bordes redondeados
        minHeight: "15rem",
        backgroundColor: "#1d1d1d",
      }}
    >
      <Box
        sx={{
          backgroundColor:
            selectedType === type ? "primary.main" : "#3E4047", // Color según selección
          width: "100%",
          borderBottom: "1px solid #3E4047",
          transition: "background-color 0.3s ease", // Transición suave
          "&:hover": {
            backgroundColor: selectedType === type
              ? "primary.main"
              : "#50545C", // Color al pasar el mouse
          },
        }}
        onClick={() => setSelectedType(type)}
      >
        <Typography
          variant="body2"
          textAlign="center"
          sx={{
            padding: "0.3rem 0.6rem",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "bold",
            textTransform: "uppercase",
            fontSize: "0.9rem",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          width: "100%",
          padding: "0.3rem",
          boxSizing: "border-box",
          backgroundColor: "transparent",
          gap: "0.1rem", // Espacio entre los chips
        }}
      >
        {Object.entries(selected).map(([key, value]) => (
          <Box
            key={key}
            sx={{
              width: "calc(100% - 1rem)", // Asegura que los chips respeten el ancho del contenedor
              maxWidth: "100%", // Limita el ancho máximo
              padding: "0.25rem",
            }}
          >
            <DxChip
              label={key}
              value={value}
              color={getColorBasedOnWeight(value)}
              importance={getImportanceBasedOnWeight(value)}
              handleRemove={() => handleRemove(key)}
              handleChangeWeight={() => handleChangeWeight(key)}
            />
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

KeyWordSelectedGrid.defaultProps = {
  selected: {},
  setSelected: () => {},
  title: "",
  setSelectedType: () => {},
  selectedType: "",
  paramInfo: { name: "", type: "string" },
};

KeyWordSelectedGrid.propTypes = {
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  setSelectedType: PropTypes.func,
  selectedType: PropTypes.string,
  paramInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default KeyWordSelectedGrid;
