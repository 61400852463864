import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import KeyWordSearchBar from "./KeywordSearchBar";
import KeyWordSelectedGrid from "../grids/KeyWordSelectedGrid";

const KeyWordSearchForm = ({ inputParams, setInputParamsHandler }) => {
  const [selectedType, setSelectedType] = useState("cuadro_actual");

  const handleSetSelectedKeywords = useCallback(
    (keywords) => {
      setInputParamsHandler(keywords, selectedType);
    },
    [selectedType, setInputParamsHandler]
  );

  if (!inputParams) {
    console.warn("inputParams no está definido.");
    return null;
  }

  // Verificar si hay keywords (pueden ser arrays u objetos)
  const hasKeywords =
    (inputParams["antecedentes"] &&
      (Array.isArray(inputParams["antecedentes"])
        ? inputParams["antecedentes"].length > 0
        : Object.keys(inputParams["antecedentes"]).length > 0)) ||
    (inputParams["cuadro_actual"] &&
      (Array.isArray(inputParams["cuadro_actual"])
        ? inputParams["cuadro_actual"].length > 0
        : Object.keys(inputParams["cuadro_actual"]).length > 0));

  console.log("inputParams: ", inputParams); // Debug

  return (
    <>
      <KeyWordSearchBar
        setSelectedKeywords={handleSetSelectedKeywords}
        selectedKeywords={inputParams[selectedType] || {}}
        selectedType={selectedType}
      />
      <Box
        sx={{
          width: "100%",
          maxHeight: hasKeywords ? "1000px" : 0, // Controla la altura máxima
          opacity: hasKeywords ? 1 : 0,
          overflow: "hidden",
          transform: hasKeywords ? "scaleY(1)" : "scaleY(0.95)",
          transformOrigin: "top",
          transition: "all 0.3s ease-in-out", // Animación suave
        }}
      >
        <Grid container sx={{ width: "100%" }} direction="row" spacing={1}>
          <Grid item xs={12} md={5}>
            <KeyWordSelectedGrid
              title="ANTECEDENTES"
              type="antecedentes"
              setSelectedType={setSelectedType}
              selectedType={selectedType}
              selected={inputParams["antecedentes"] || []}
              setSelected={setInputParamsHandler}
              paramInfo={{ name: "antecedentes", type: "string" }}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <KeyWordSelectedGrid
              title="CUADRO CLÍNICO ACTUAL"
              type="cuadro_actual"
              setSelectedType={setSelectedType}
              selectedType={selectedType}
              selected={inputParams["cuadro_actual"] || []}
              setSelected={setInputParamsHandler}
              paramInfo={{ name: "cuadro_actual", type: "string" }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

KeyWordSearchForm.propTypes = {
  inputParams: PropTypes.shape({
    antecedentes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    cuadro_actual: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  setInputParamsHandler: PropTypes.func.isRequired,
};

export default KeyWordSearchForm;
