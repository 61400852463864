import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import ExamItem from "../lists/ExamItem";
import { motion } from "framer-motion"; // Importa framer-motion
import propTypes from "prop-types";

const FindingTwoColumnGrid = ({ title, exams, maxHeight, columns }) => {
  const entries = Object.entries(exams);

  // Calcula el tamaño en xs basado en las columnas deseadas
  const columnSize = Math.floor(12 / columns);

  // Procesar el título para eliminar "Ex" al principio
  const processedTitle = title.startsWith("Ex ")
    ? title.replace("Ex ", "").toUpperCase() // Reemplaza y convierte a mayúsculas
    : title.toUpperCase(); // Si no empieza con "Ex ", igual lo convierte a mayúsculas

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.default",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Título */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "background.dark",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "text.primary",
            fontWeight: "bold",
          }}
        >
          {processedTitle}
        </Typography>
      </Box>

      {/* Contenido Scrollable */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto", // Scroll interno
          padding: "1rem",
          maxHeight: maxHeight || "200px", // Limitar altura máxima a 200px por defecto
        }}
      >
        <Grid container spacing={2}>
          {entries.map(([key, value], index) => (
            <Grid
              key={index}
              item
              xs={columnSize}
              component={motion.div} // Agrega motion.div para animar
              initial={{ opacity: 0, y: 20 }} // Estado inicial
              animate={{ opacity: 1, y: 0 }} // Estado final
              transition={{
                duration: 0.9,
                delay: index * 0.1, // Retardo para que aparezcan uno tras otro
              }}
            >
              <ExamItem name={key} info={value} number={index + 1} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

FindingTwoColumnGrid.propTypes = {
  title: propTypes.string,
  exams: propTypes.object.isRequired,
  maxHeight: propTypes.string, // Nueva propiedad opcional para limitar la altura
  columns: propTypes.number, // Cantidad de columnas por fila
};

FindingTwoColumnGrid.defaultProps = {
  columns: 2, // Por defecto 2 columnas por fila
};

export default FindingTwoColumnGrid;
