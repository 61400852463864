import React, { useEffect, useMemo, useState, useRef } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Graph from "react-vis-network-graph";
import { getGraphInfo } from "../helpers/paths-info-helpers";
import { v4 as uuidv4 } from "uuid";
import { useIsMobile } from "../hooks";
import {
  ChangeHistoryTwoTone,
  CircleTwoTone,
  SquareTwoTone,
} from "@mui/icons-material";

function DxGraph() {
  const navigate = useNavigate();
  const { recordId } = useParams();
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const [graph, setGraph] = useState({ nodes: [], edges: [] });
  const networkRef = useRef(null);
  const originalGraphRef = useRef({ nodes: [], edges: [] });

  const formatNodes = (nodes, startNode, inputFindings) => {
    return nodes.map((node) => ({
      id: node,
      label: node.length > 25 ? `${node.slice(0, 25)}...` : node,
      shape: inputFindings.includes(node)
        ? "diamond"
        : node === startNode
        ? "dot"
        : "triangle",
      color: inputFindings.includes(node)
        ? "#da03b3"
        : node === startNode
        ? "#ff9906"
        : "#01CACF",
      font: { size: 18, color: "white" },
      title: `Full name: ${node}`,
    }));
  };

  const formatEdges = (edges, startNode, inputFindings) => {
    return edges.map((edge) => ({
      id: uuidv4(),
      from: edge.from,
      to: edge.to,
      color:
        edge.from === startNode
          ? "#ff9906"
          : inputFindings.includes(edge.from)
          ? "#da03b3"
          : "#01CACF",
    }));
  };

  useEffect(() => {
    if (searchParams.get("paths_info")) {
      const pathsInfo = JSON.parse(searchParams.get("paths_info"));
      const { nodes, edges, startNode } = getGraphInfo(recordId, pathsInfo);
      const inputFindings = JSON.parse(searchParams.get("input_findings"));

      const formattedNodes = formatNodes(nodes, startNode, inputFindings);
      const formattedEdges = formatEdges(edges, startNode, inputFindings);

      const formattedGraph = { nodes: formattedNodes, edges: formattedEdges };
      setGraph(formattedGraph);
      originalGraphRef.current = formattedGraph;
    }
  }, [searchParams, recordId]);

  const options = useMemo(
    () => ({
      layout: {
        hierarchical: false,
        improvedLayout: true,
      },
      height: isMobile ? "600px" : "800px",
      nodes: {
        borderWidth: 2,
        font: { size: 20, color: "white", align: "center" },
        labelHighlightBold: true,
        shape: "dot",
        size: 20,
      },
      edges: {
        smooth: { type: "dynamic", roundness: 0.5 },
        arrows: { to: { enabled: true, scaleFactor: 1.5 } },
        color: { inherit: "from" },
      },
      physics: {
        enabled: true,
        barnesHut: {
          gravitationalConstant: -2000,
          centralGravity: 0.3,
          springLength: 150,
          springConstant: 0.05,
          damping: 0.4,
          avoidOverlap: 1.0,
        },
        solver: "barnesHut",
        stabilization: {
          iterations: 100, 
        },
      },
      interaction: {
        tooltipDelay: 300,
        hover: true,
        navigationButtons: true,
        dragNodes: true,
        zoomView: true,
      },
    }),
    [isMobile]
  );

  const events = {
    click: (params) => {
      if (params.nodes && params.nodes.length > 0) {
        const clickedNodeId = params.nodes[0];
        const connectedNodes = networkRef.current.getConnectedNodes(clickedNodeId);
        const highlightNodes = new Set([clickedNodeId, ...connectedNodes]);

        const updatedNodes = originalGraphRef.current.nodes.map((node) => {
          return highlightNodes.has(node.id)
            ? { 
                ...node, 
                opacity: 1, 
                font: { ...node.font, color: "white" } 
              }
            : { 
                ...node, 
                opacity: 0.1, 
                font: { ...node.font, color: "rgba(255,255,255,0.1)" } 
              };
        });

        const updatedEdges = originalGraphRef.current.edges.map((edge) => {
          if (highlightNodes.has(edge.from) && highlightNodes.has(edge.to)) {
            return {
              ...edge,
              color: { ...edge.color, opacity: 1 },
            };
          } else {
            return {
              ...edge,
              color: { ...edge.color, opacity: 0.1 },
            };
          }
        });

        networkRef.current.setData({ nodes: updatedNodes, edges: updatedEdges });
      } else {
        // Clic fuera de un nodo: restaurar
        networkRef.current.setData({
          nodes: originalGraphRef.current.nodes,
          edges: originalGraphRef.current.edges,
        });
      }
    },
  };

  useEffect(() => {
    // Al desmontar el componente, restaurar el estado original
    return () => {
      if (networkRef.current && originalGraphRef.current.nodes.length > 0) {
        networkRef.current.setData({
          nodes: originalGraphRef.current.nodes,
          edges: originalGraphRef.current.edges,
        });
      }
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "10px",
        overflow: "hidden",
        height: "800px",
      }}
    >
      <Graph
        key={uuidv4()}
        graph={graph}
        options={options}
        events={events}
        getNetwork={(network) => (networkRef.current = network)}
      />

      {/* Título en la parte superior centrada */}
      <Box
        sx={{
          position: "absolute",
          top: "5%",
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
          color: "white",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", fontFamily: "Montserrat, sans-serif" }}
        >
          {recordId}
        </Typography>
        <Typography
          variant="body2"
          sx={{ marginTop: 1, fontFamily: "Roboto, sans-serif" }}
        >
          Explora las relaciones entre los distintos componentes que explican el
          diagnóstico.
        </Typography>
      </Box>

      {/* Leyenda y botón en la esquina superior derecha */}
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          borderRadius: "8px",
          padding: "10px",
          zIndex: 2,
        }}
      >
        <Stack spacing={1}>
          {[
            {
              label: "Diagnóstico probable actual",
              icon: <CircleTwoTone sx={{ color: "#ff9906", fontSize: 16 }} />,
            },
            {
              label: "Hallazgos actuales del paciente",
              icon: (
                <SquareTwoTone
                  sx={{
                    transform: "rotate(45deg)",
                    color: "#da03b3",
                    fontSize: 16,
                  }}
                />
              ),
            },
            {
              label: "Otros diagnósticos asociados",
              icon: (
                <ChangeHistoryTwoTone sx={{ color: "#01CACF", fontSize: 16 }} />
              ),
            },
          ].map((item) => (
            <Stack
              key={item.label}
              direction="row"
              spacing={0.5}
              alignItems="center"
            >
              {item.icon}
              <Typography
                variant="caption"
                sx={{ color: "white", fontFamily: "Roboto, sans-serif" }}
              >
                {item.label}
              </Typography>
            </Stack>
          ))}
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate(`/record/${recordId}`)}
            sx={{
              marginTop: 1,
              backgroundColor: "secondary.main",
              color: "black",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Ver Ficha
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default DxGraph;
