import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import Tooltip from "@mui/material/Tooltip";

const DxPregnantProbabilityInput = ({ selectedValue, setValue }) => {
  const handleToggle = useCallback(() => {
    setValue(!selectedValue, "prob_embarazo", "toggle");
  }, [selectedValue, setValue]);

  return (
    <Tooltip title="Probabilidad de embarazo">
      <IconButton
        onClick={handleToggle}
        sx={{
          width: "2.5rem",
          height: "2.5rem",
          borderRadius: "50%",
          backgroundColor: selectedValue ? "#1F6F97" : "background.default",
          border: "2px solid",
          borderColor: selectedValue ? "#1F6F97" : "grey.300",
          color: selectedValue ? "white" : "grey.600",
          boxShadow: selectedValue
            ? "0px 4px 8px rgba(31, 111, 151, 0.5)"
            : "0px 2px 4px rgba(0, 0, 0, 0.1)",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: selectedValue ? "#135273" : "grey.100",
            borderColor: selectedValue ? "#135273" : "grey.400",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
          },
          "&:active": {
            transform: "scale(0.95)",
          },
        }}
      >
        <PregnantWomanIcon sx={{ fontSize: "1.5rem" }} />
      </IconButton>
    </Tooltip>
  );
};

DxPregnantProbabilityInput.propTypes = {
  selectedValue: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default DxPregnantProbabilityInput;
