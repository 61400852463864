import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import renderMethodMap from "../../views/record/render-methods";
import { Link } from "@mui/icons-material";

const RecordCard = ({ title, content, minHeight, metadata }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [collapsedHeight, setCollapsedHeight] = useState(`${minHeight}px`);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkContentHeight = () => {
      const contentHeight = contentRef.current?.clientHeight;
      if (contentHeight > minHeight) {
        setShowButton(true);
        setCollapsedHeight(`${minHeight}px`);
      } else {
        setShowButton(false);
        setCollapsedHeight(`${contentHeight}px`);
      }
    };

    checkContentHeight();
    window.addEventListener("resize", checkContentHeight);

    return () => {
      window.removeEventListener("resize", checkContentHeight);
    };
  }, [minHeight, content, contentRef.current?.clientHeight]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const seeMoreButton = (
    <Button
      onClick={toggleExpanded}
      fullWidth
      sx={{
        color: "tertiary.main",
        borderRadius: 0,
        borderTop: "1px solid",
        borderColor: "background.paper",
        padding: "0.2rem",
      }}
    >
      {isExpanded ? "Ver menos" : "Ver más"}
    </Button>
  );

  const RenderComponent = renderMethodMap[metadata.type];

  return (
    <Stack key={title} direction="column">
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          backgroundColor: "background.dark",
          padding: "0.2rem 0.8rem",
          width: "fit-content",
          borderRadius: "0.5rem 0.5rem 0 0",
        }}
      >
        <Typography variant="h5">{title}</Typography>
        {metadata.type === "hyperlink" && (
          <>
            <Tooltip
              title="Puedes hacer click en los chips para navegar entre fichas"
              arrow
            >
              <Link color="secondary" />
            </Tooltip>
          </>
        )}
      </Stack>
      <Box
        sx={{
          backgroundColor: "background.paper",
          border: "1px solid",
          borderColor: "background.dark",
          position: "relative",
        }}
      >
        <Collapse in={isExpanded} collapsedSize={collapsedHeight}>
          <Box
            ref={contentRef}
            sx={{
              padding: "0.5rem 0.8rem",
            }}
          >
            <RenderComponent name={title} content={content} />
          </Box>
        </Collapse>
        {showButton && seeMoreButton}
      </Box>
    </Stack>
  );
};

RecordCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  minHeight: PropTypes.number,
};

RecordCard.defaultProps = {
  minHeight: 150,
};

export default RecordCard;
