import React, { useState, useCallback } from "react";
import {
  Stack,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Box,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import SpecificDxsModal from "../modals/SpecificDxsModal";

function ExamItem({ name, info, number }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeState, setActiveState] = useState(0); // Estado para manejar el fondo (0 = blanco, 1 = color1, 2 = color2)
  const navigate = useNavigate();
  const openMenu = Boolean(anchorEl);

  // Handlers for menu and modal
  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNavigateClick = useCallback(() => {
    navigate(`/record/${name}`);
    handleMenuClose();
  }, [navigate, name, handleMenuClose]);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  // Cambiar el fondo entre los tres estados
  const toggleBackground = useCallback(() => {
    setActiveState((prev) => (prev + 1) % 3); // Cambia entre 0, 1 y 2
  }, []);

  // Determinar el color de fondo según el estado
  const backgroundColor =
    activeState === 0
      ? "background.paper" // Blanco
      : activeState === 1
      ? "#F34D67" // Primer color
      : "#1f6f97"; // Segundo color

  return (
    <>
      <Stack
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: backgroundColor, // Fondo dinámico
          marginY: 0.3,
          padding: "0.1rem 0.6rem",
          borderRadius: "4px",
          maxWidth: "24rem",
          transition: "all 0.3s ease-in-out", // Transición suave
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra inicial
          cursor: "pointer", // Cursor de puntero
          "&:hover": {
            transform: "scale(1.03)", // Efecto de aumento
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // Sombra más prominente
          },
        }}
      >
        {/* Círculo con el número */}
        <Box
          sx={{
            width: "1.5rem",
            height: "1.5rem",
            borderRadius: "50%",
            backgroundColor: "#3E4047",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "0.8rem",
            marginRight: "0.75rem",
            flexShrink: 0,
          }}
        >
          {number}
        </Box>
        <Tooltip title={name} placement="bottom" arrow>
          <Typography
            variant="body1"
            sx={{
              color: "text.dark",
              flexGrow: 1,
              fontWeight: "regular",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={handleOpenModal}
          >
            {name}
          </Typography>
        </Tooltip>
        {/* Botón para el menú */}
        <IconButton
          onClick={handleMenuOpen}
          sx={{
            color: "text.dark",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "background.default",
            },
          }}
        >
          <MoreVert
            sx={{
              fontSize: "1.5rem",
            }}
          />
        </IconButton>

        {/* Nuevo botón para alternar fondo */}
        <IconButton
          onClick={toggleBackground}
          sx={{
            color: "text.dark",
            backgroundColor: "transparent",
            borderRadius: "50%",
            width: "2.5rem",
            height: "2.5rem",
            fontSize: "1.2rem",
            marginLeft: "0.5rem", // Espaciado entre botones
            "&:hover": {
              backgroundColor: "background.default",
            },
          }}
        >
          !
        </IconButton>

        {/* Menu */}
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          sx={{ maxHeight: 200 }}
        >
          <MenuItem onClick={handleNavigateClick}>Ver ficha</MenuItem>
        </Menu>
      </Stack>
      <SpecificDxsModal
        openSpecificDxs={open}
        specificDxs={info["related_findings"]}
        selectedGroup={name}
        handleGroupClose={handleCloseModal}
      />
    </>
  );
}

ExamItem.propTypes = {
  name: PropTypes.string.isRequired,
  info: PropTypes.arrayOf(PropTypes.string).isRequired,
  number: PropTypes.number.isRequired, // Número del ítem
  handleChangeWeight: PropTypes.func,
  handleNavigate: PropTypes.func,
  handleRemove: PropTypes.func,
};

ExamItem.defaultProps = {
  handleChangeWeight: undefined,
  handleNavigate: undefined,
  handleRemove: undefined,
};

export default ExamItem;
