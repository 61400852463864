import { useEffect, useMemo, useState } from "react";
import { Female, Male } from "@mui/icons-material";
import getClient from "../helpers/get-client";

const useInputParamsInfo = () => {
  const client = getClient();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState(null);
  useEffect(() => {
    if (autoCompleteOptions) return;
    client
      .get(
        `${process.env.REACT_APP_BACKEND_URI}api/medical_analysis/get-auto-complete-options/`,
      )
      .then((response) => {
        setAutoCompleteOptions(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [autoCompleteOptions, client]);
  const inputParamsInfo = useMemo(
    () => [
      {
        name: "sexo",
        label: "Sexo",
        type: "select",
        options: [
          {
            value: "Masculino",
            label: <Male />,
          },
          {
            value: "Femenino",
            label: <Female />,
          },
        ],
      },
      {
        name: "edad",
        label: "Edad",
        placeholder: "Ej: 25",
        type: "age",
        adornment: "años",
      },
      {
        name: "prob_embarazo", // ToDo: Give it a suggested default value based on the patient's age and gender
        label: "Posibilidad de embarazo",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "antecedentes",
        label: "Antecedentes",
        placeholder: "Ingresa una antecedentes",
        defaultValue: {},
        type: "dict",
        autoCompleteOptions: autoCompleteOptions?.antecedentes,
      },
      {
        name: "cuadro_actual",
        label: "Cuadro Actual",
        placeholder: "Ingresa una Cuadro Actual",
        defaultValue: {},
        type: "dict",
        autoCompleteOptions: autoCompleteOptions?.cuadro_actual,
      },
      {
        name: "examen_fisico",
        label: "Exámenes físicos",
        placeholder: "Ingresa un examen físico",
        defaultValue: {},
        type: "dict",
        autoCompleteOptions: autoCompleteOptions?.examen_fisico,
      },
      {
        name: "alteraciones_en_examenes",
        label: "Alteraciones en exámenes",
        placeholder: "Ingresa una alteración en exámenes",
        defaultValue: {},
        type: "dict",
        autoCompleteOptions: autoCompleteOptions?.alteraciones_en_examenes,
      },
      {
        name: "discarded_dx",
        label: "Diagnósticos descartados",
        placeholder: "Ingresa un diagnóstico descartado",
        defaultValue: [],
        type: "list",
        autoCompleteOptions: autoCompleteOptions?.discarded_dx,
      },
      {
        name: "include_med_history",
        label: "¿Incluir antecedentes?",
        defaultValue: false,
        type: "boolean",
        advanced: true,
      },
      {
        name: "include_drugs",
        label: "¿Incluir fármacos?",
        defaultValue: false,
        type: "boolean",
        advanced: true,
      },
      {
        name: "min_findings_out",
        label: "MFO",
        defaultValue: 1,
        type: "number",
        advanced: true,
      },
      {
        name: "max_connections_out",
        label: "MCO",
        defaultValue: 2,
        type: "number",
        maxValue: 2,
        advanced: true,
      },
    ],
    [autoCompleteOptions],
  );

  return inputParamsInfo;
};

export default useInputParamsInfo;
