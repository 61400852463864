import React, { useState } from "react";
import Box from "@mui/material/Box";
import KeyWordSearchBar from "../components/forms/KeywordSearchBar";
import KeyWordGrid from "../components/grids/KeyWordGrid";

const DxFinder = () => {
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  return (
    <Box>
      <KeyWordSearchBar
        selectedKeywords={selectedKeywords}
        setSelectedKeywords={setSelectedKeywords}
      />
      <KeyWordGrid
        selectedKeywords={selectedKeywords}
        setSelectedKeywords={setSelectedKeywords}
      />
    </Box>
  );
};

export default DxFinder;
