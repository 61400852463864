import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Thermometer } from "../progress-bars";
import SpecificDxsModal from "../modals/SpecificDxsModal"; // Import the modal
import { LineLimitTypography } from "../typography";
import { Assignment, Insights, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getPathInfo } from "../../helpers/paths-info-helpers";
import { useDxData } from "../context/DxDataContext";

const DxResultCard = ({ dxResultData, specificDxsData, ranking }) => {
  const [openSpecificDxs, setOpenSpecificDxs] = useState(false);

  const navigate = useNavigate();
  const { dxData } = useDxData();

  const handleCardClick = () => setOpenSpecificDxs(true);
  const handleGroupClose = () => setOpenSpecificDxs(false);

  const specificDxsNames = specificDxsData
    .slice(1)
    .map((dx) => dx.name)
    .join(", ");

  const goToRecord = () => {
    navigate(`/record/${dxResultData?.top_dx}`);
  };

  const goToGraph = (specificDx) => {
    const { dxPathsInfo } = getPathInfo(specificDx.name, dxData?.paths_info);
    const encodedPathsInfo = encodeURIComponent(JSON.stringify(dxPathsInfo));
    const encodedInputFindings = encodeURIComponent(
      JSON.stringify(dxData?.input_findings)
    );

    navigate(
      `/graph/${specificDx.name}?paths_info=${encodedPathsInfo}&input_findings=${encodedInputFindings}`
    );
  };

  return (
    <>
      <Grid lg={4} md={6} xs={12} item>
        <Stack
          onClick={handleCardClick}
          direction="row"
          sx={{
            cursor: "pointer",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.03)", // Efecto de aumento
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // Sombra más prominente
            },
          }}
        >
          <Thermometer value={dxResultData?.top_score} max={1} min={0} />
          <Stack
            direction="column"
            spacing={1}
            sx={{
              padding: "0.5rem",
              width: "100%",
              height: "6.2rem",
              overflowY: "auto",
              paddingY: 1,
            }}
          >
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  backgroundColor: "background.lighter",
                  padding: "0.2rem",
                  borderRadius: "10rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "1.2rem",
                  height: "1.2rem",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.dark",
                    fontWeight: 700,
                  }}
                >
                  {ranking}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 700,
                  color: "text.dark",
                  width: "100%",
                }}
              >
                {dxResultData?.groupdx}
              </Typography>
            </Stack>
            <Typography
              variant="body2"
              sx={{
                color: "text.dark",
              }}
            >
              <Box component="span" sx={{ fontWeight: 700 }}>
                Top Dx:
              </Box>{" "}
              {dxResultData?.top_dx}
            </Typography>
            {specificDxsData.length > 1 && (
              <LineLimitTypography
                maxLines={2}
                variant="body2"
                color="text.dark"
                fontSize="0.8rem"
              >
                <Box component="span" sx={{ fontWeight: 700 }}>
                  Other Dxs:
                </Box>{" "}
                {specificDxsNames}
              </LineLimitTypography>
            )}
          </Stack>
          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{
              boxShadow: "inset 3px 0px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Tooltip title="Ver grafo" arrow placement="left">
              <Box
                onClick={(e) => {
                  e.stopPropagation(); // Evita abrir el modal al hacer clic en el botón
                  goToGraph(specificDxsData[0]);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingY: "0.45rem",
                  paddingX: "0.5rem",
                  transition: "background-color 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "background.paper",
                  },
                }}
              >
                <Insights />
              </Box>
            </Tooltip>
            <Tooltip title="Ver ficha" arrow placement="left">
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  goToRecord();
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingY: "0.45rem",
                  paddingX: "0.5rem",
                  transition: "background-color 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "background.paper",
                  },
                }}
              >
                <Assignment />
              </Box>
            </Tooltip>
            <Tooltip title="Ver detalle" arrow placement="left">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingY: "0.45rem",
                  paddingX: "0.5rem",
                  transition: "background-color 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "background.paper",
                  },
                }}
              >
                <MoreVert />
              </Box>
            </Tooltip>
          </Stack>
        </Stack>
      </Grid>

      <SpecificDxsModal
        openSpecificDxs={openSpecificDxs}
        specificDxs={specificDxsData}
        selectedGroup={dxResultData?.groupdx}
        handleGroupClose={handleGroupClose}
      />
    </>
  );
};

DxResultCard.propTypes = {
  dxResultData: PropTypes.object.isRequired,
  specificDxsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
    })
  ).isRequired,
  ranking: PropTypes.number.isRequired,
};

export default DxResultCard;
