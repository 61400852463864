import axios from "axios";

axios.defaults.withCredentials = true;

const getClient = () => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URI,
  });

  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    client.defaults.headers.common["Authorization"] = `Token ${authToken}`;
  }

  return client;
};

export default getClient;
