import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Stack, IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Clear, MoreVert } from "@mui/icons-material";

const DxChip = ({ value, color, label, handleChangeWeight, handleRemove }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleChangeWeightClick = () => {
    handleChangeWeight && handleChangeWeight(label);
    handleMenuClose();
  };
  const handleNavigateClick = () => {
    navigate(`/record/${label}`);
    handleMenuClose();
  };
  const handleRemoveClick = () => {
    handleRemove(label);
    handleMenuClose();
  };
  const handleChipClick = () => {
    handleChangeWeight && handleChangeWeight(label);
  };

  return (
    <Box
      onClick={handleChipClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#1C1C1E",
        borderRadius: "6px",
        overflow: "hidden",
        boxShadow: `inset 0 0 0 2px ${color}`,
        cursor: "pointer",
        transition: "all 0.2s ease",
        width: "fit-content", // Permite que el chip solo ocupe el espacio necesario
        maxWidth: "100%", // Asegura que no exceda el ancho del contenedor padre
        "&:hover": {
          backgroundColor: "#2A2A2C",
          boxShadow: `inset 0 0 0 2px ${color}, 0 0 10px rgba(0,0,0,0.3)`,
        },
      }}
    >
      <Tooltip title={label} placement="bottom" arrow>
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0.4rem 0.8rem",
            fontWeight: 500,
            color: "#E0E0E0",
            fontSize: "0.8rem",
            textTransform: "capitalize",
            letterSpacing: "0.5px",
            textShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
            transition: "color 0.2s ease",
            maxWidth: "12rem", // Limita el ancho máximo de texto
            "&:hover": {
              color: "#FFFFFF",
            },
          }}
        >
          {label}
        </Box>
      </Tooltip>

      {/* Botones */}
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          display: "flex",
          alignItems: "center",
          marginRight: "0.2rem",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          size="small"
          onClick={handleMenuOpen}
          sx={{
            color: "#AAAAAA",
            backgroundColor: "transparent",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              color: "#FFFFFF",
            },
          }}
        >
          <MoreVert fontSize="small" />
        </IconButton>
        <IconButton
          onClick={handleRemoveClick}
          sx={{
            color: "#E57373",
            padding: "0.2rem",
            backgroundColor: "transparent",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: "rgba(229, 115, 115, 0.2)",
              color: "#FF5252",
            },
          }}
        >
          <Clear sx={{ fontSize: "1rem" }} />
        </IconButton>
      </Stack>

      {/* Menú desplegable */}
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        sx={{ maxHeight: 200, width: 200 }}
      >
        {handleChangeWeight && (
          <MenuItem onClick={handleChangeWeightClick}>Cambiar peso</MenuItem>
        )}
        <MenuItem onClick={handleNavigateClick}>Ver ficha</MenuItem>
      </Menu>
    </Box>
  );
};

DxChip.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  handleChangeWeight: PropTypes.func,
  handleRemove: PropTypes.func.isRequired,
};

DxChip.defaultProps = {
  color: "#3E4047",
  handleChangeWeight: undefined,
};

export default DxChip;
