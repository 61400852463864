import { useState } from "react";
import getClient from "../helpers/get-client";
import { useDxData } from "../components/context/DxDataContext";

const useDxFetch = (inputParams) => {
  const [loading, setLoading] = useState(false);
  const { setDxData } = useDxData();
  const client = getClient();

  const fetchDxData = async () => {
    setLoading(true);
    const inputParamsString = JSON.stringify(inputParams);
    try {
      const response = await client.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/medical_analysis/get-dx/`,
        {
          params: { inputParams: inputParamsString },
        },
      );
      setDxData(response.data);
    } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, fetchDxData };
};

export default useDxFetch;
