import React from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Box } from "@mui/material";

const ageParam = {
  name: "edad",
  label: "Edad",
  placeholder: "Ingrese su edad",
  type: "age",
  adornment: "años",
};

const DxAgeField = ({ selectedValue, setValue }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
      <TextField
        variant="outlined"
        type="number"
        placeholder={ageParam.placeholder}
        value={selectedValue}
        onChange={(event) =>
          setValue(event.target.value, ageParam.name, ageParam.type)
        }
        InputProps={{
          sx: {
            backgroundColor: "background.paper",
            borderRadius: "12px",
            height: "2.5rem",
            paddingRight: "0.5rem",
            maxWidth: "120px", // Ajusta el ancho del TextField a un tamaño más compacto
            "& input": {
              color: "black", // Texto principal en negro
              fontSize: "1rem",
              padding: "0.8rem",
            },
            "& input::placeholder": {
              fontSize: "0.9rem",
              color: "black", // Placeholder en negro
            },
          },
          endAdornment: (
            <Typography
              variant="body2"
              sx={{
                color: "black", // Adorno también en negro
                fontSize: "0.9rem",
                marginLeft: "0.5rem",
              }}
            >
              {ageParam.adornment}
            </Typography>
          ),
        }}
      />
    </Box>
  );
};

DxAgeField.propTypes = {
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setValue: PropTypes.func.isRequired,
};

export default DxAgeField;
