import React, { useState, useRef } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import RecordIndex from "./RecordIndex";
import SkeletonCard from "./SkeletonCard";
import ActionGrid from "./ActionGrid";

const RecordStructure = ({ name, children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const loading = false;

  const sectionRefs = useRef({});
  const contentRef = useRef(null);

  const scrollToSection = (key) => {
    if (sectionRefs.current[key] && contentRef.current) {
      const element = sectionRefs.current[key].current;
      const yOffset = -15;
      const y =
        element.getBoundingClientRect().top +
        contentRef.current.scrollTop -
        contentRef.current.getBoundingClientRect().top +
        yOffset;
      contentRef.current.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <Stack
      direction="row"
      position="relative"
      sx={{
        height: "89vh",
        display: "flex",
      }}
    >
      <RecordIndex
        recordData={{}}
        loading={false}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        scrollToSection={scrollToSection}
      />
      <Stack
        spacing={3}
        sx={{
          backgroundColor: "background.paper",
          padding: "1rem",
          margin: { xs: "0 0 0 3.5rem", md: "0" },
          overflowY: "scroll",
          position: "relative",
          width: "100%",
        }}
        ref={contentRef}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            border: "1px solid",
            padding: "1rem",
          }}
        >
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h2" color="text.dark" align="center">
              {name}
            </Typography>
          </Grid>
          <ActionGrid />
        </Grid>
        <Stack direction="column" spacing={2}>
          {loading && (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          )}
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecordStructure;
