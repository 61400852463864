import React from "react";
import { Stack, Typography } from "@mui/material";
import AuthenticationForm from "./authentication/AuthenticationForm";

function Authentication() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      textAlign="center"
      sx={{
        justifyContent: "center",
        backgroundColor: "background.default",
        my: 10,
      }}
    >
      <Typography
        sx={{
          color: "text.primary",
          fontWeight: "bold",
          fontSize: { xs: "h4.fontSize", sm: "h3.fontSize" },
          mb: 2,
        }}
        variant="h1"
      >
        ¡Bienvenido a SmartDx!
      </Typography>
      <AuthenticationForm />
    </Stack>
  );
}

export default Authentication;


