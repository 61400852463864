import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const LineLimitTypography = ({ children, maxLines, ...typographyProps }) => {
  return (
    <Typography
      {...typographyProps}
      sx={{
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: maxLines,
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </Typography>
  );
};

LineLimitTypography.propTypes = {
  children: PropTypes.node.isRequired,
  maxLines: PropTypes.number.isRequired,
};

export default LineLimitTypography;
