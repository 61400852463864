import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F6F97",
    },
    secondary: {
      main: "#FCF0CC",
    },
    tertiary: {
      main: "#508AA8",
    },
    danger: {
      main: "#D62246",
    },
    background: {
      default: "#1d1d1d",
      dark: "#202125",
      light: "#474A53",
      paper: "#ffffff",
      lighter: "#CCCCCC",
      black: "#000000",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FCF0CC",
      dark: "#1A1A1A",
    },
    temperature: {
      high: "#F34D67",
      medium: "#F17D4E",
      low: "#ECF226",
      highDark: "#D62246",
      mediumDark: "#D17C4E",
      lowDark: "#C9D226",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: { fontSize: "2rem", fontWeight: 700, color: "#FFFFFF" },
    h2: { fontSize: "1.75rem", fontWeight: 700, color: "#FFFFFF" },
    h3: { fontSize: "1.5rem", color: "#FFFFFF" },
    h4: { fontSize: "1.25rem", color: "#FFFFFF" },
    h5: { fontSize: "1rem", color: "#FFFFFF" },
    h6: { fontSize: "0.875rem", color: "#FFFFFF" },
    subtitle1: { fontSize: "1rem", color: "#FFFFFF" },
    subtitle2: { fontSize: "0.875rem", color: "#FFFFFF" },
    body1: { fontSize: "1rem", color: "#FFFFFF" },
    body2: { fontSize: "0.875rem", color: "#FFFFFF" },
    button: { fontSize: "1rem", color: "#FFFFFF" },
    caption: { fontSize: "0.75rem", color: "#FFFFFF" },
    overline: { fontSize: "0.75rem", color: "#FFFFFF" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          borderRadius: "4px",
          "&:hover": {
            backgroundColor: "#1F6F97",
          },
        },
        link: {
          color: "#FCF0CC",
          fontWeight: 500,
          textDecoration: "underline",
          "&:hover, &:active": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          backgroundColor: "#1F6F97",
          "&:hover": {
            backgroundColor: "#1F6F97",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            color: "#FFFFFF",
            fontWeight: 700,
            backgroundColor: "#1F6F97",
            border: "1px solid #FFFFFF",
          },
          "& .MuiTableCell-body": {
            color: "#FFFFFF",
            backgroundColor: "#474A53",
            border: "1px solid #1F6F97",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#333745",
          color: "#FFFFFF",
          border: "1px solid #1F6F97",
          "&.Mui-expanded": {
            backgroundColor: "#474A53",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#474A53",
          color: "#FFFFFF",
          borderBottom: "1px solid #1F6F97",
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#474A53",
          color: "#FFFFFF",
          padding: "8px 16px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          backgroundColor: "#474A53",
          borderRadius: "4px",
          "&:focus": {
            backgroundColor: "#474A53",
          },
        },
        icon: {
          color: "#FFFFFF",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#474A53",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#474A53",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#1F6F97",
          },
          "&.Mui-selected": {
            backgroundColor: "#1F6F97",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          "&.Mui-focused": {
            color: "#FCF0CC",
          },
        },
      },
    },
  },
});

export default theme;
