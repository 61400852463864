import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack, Chip } from "@mui/material";
import PropTypes from "prop-types";

const KeyWordGrid = ({ selectedKeywords, setSelectedKeywords }) => {
  const handleDeleteChip = (chipToDelete) => () => {
    setSelectedKeywords((chips) =>
      chips.filter((chip) => chip !== chipToDelete),
    );
  };

  return (
    <Box>
      <Typography variant="h6" component="div" gutterBottom>
        Selected Keywords
      </Typography>
      <Stack direction="row" spacing={1}>
        {selectedKeywords.map((keyword, index) => (
          <Chip
            key={index}
            label={keyword}
            onDelete={handleDeleteChip(keyword)}
            color="primary"
          />
        ))}
      </Stack>
    </Box>
  );
};

KeyWordGrid.defaultProps = {
  selectedKeywords: [],
  setSelectedKeywords: () => {},
};

KeyWordGrid.propTypes = {
  selectedKeywords: PropTypes.array,
  setSelectedKeywords: PropTypes.func,
};

export default KeyWordGrid;
