import React, { createContext, useContext, useState, useEffect } from "react";

const DxDataContext = createContext();

export const useDxData = () => useContext(DxDataContext);

export const DxDataProvider = ({ children }) => {
  const [dxData, _setDxData] = useState(null);

  const setDxData = (newDxData) => {
    if (newDxData) {
      localStorage.setItem("dxData", JSON.stringify(newDxData));
    }
    _setDxData(newDxData);
  };

  // Load stored data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("dxData");
    if (storedData) {
      setDxData(JSON.parse(storedData));
    }
  }, []);

  return (
    <DxDataContext.Provider value={{ dxData, setDxData }}>
      {children}
    </DxDataContext.Provider>
  );
};
