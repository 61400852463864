import React from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowBackIosNew, Menu } from "@mui/icons-material";
import { useIsMobile } from "../../hooks";

const RecordIndex = ({
  recordData,
  columnsMetadata,
  loading,
  menuOpen,
  setMenuOpen,
  scrollToSection,
}) => {
  const isMobile = useIsMobile();

  return (
    <Stack
      sx={{
        backgroundColor: "background.dark",
        position: { xs: "absolute", md: "relative" },
        zIndex: 999,
        height: { xs: "100%", md: "auto" },
      }}
    >
      {!menuOpen ? (
        <Menu
          sx={{
            alignSelf: menuOpen ? "flex-end" : "center",
            margin: "1rem 1rem 0 1rem",
            color: "background.paper",
            "&:hover": {
              color: "primary.main",
              cursor: "pointer",
            },
          }}
          onClick={() => setMenuOpen(true)}
        />
      ) : (
        <ArrowBackIosNew
          sx={{
            alignSelf: "flex-end",
            margin: "1rem 1rem 0 1rem",
            color: "background.paper",
            "&:hover": {
              color: "primary.main",
              cursor: "pointer",
            },
          }}
          onClick={() => setMenuOpen(false)}
        />
      )}
      <Collapse in={menuOpen} orientation="horizontal">
        <Stack
          direction="column"
          spacing={1.5}
          sx={{
            padding: "1rem 1.5rem 1rem 1.5rem",
            width: "15rem",
            height: "75vh",
            overflowY: "scroll",
          }}
        >
          <Typography variant="h4" fontWeight={700}>
            Contenidos
          </Typography>
          <List>
            {!loading &&
              recordData &&
              Object.keys(recordData).map((key) => (
                <ListItem disablePadding key={key} divider={true}>
                  <ListItemButton
                    onClick={() => {
                      scrollToSection(key);
                      if (isMobile) {
                        setMenuOpen(false);
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "text.dark",
                      },
                    }}
                  >
                    <Typography variant="body2">
                      {columnsMetadata[key].display_name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Stack>
      </Collapse>
    </Stack>
  );
};

RecordIndex.propTypes = {
  recordData: PropTypes.object,
  columnsMetadata: PropTypes.object,
  loading: PropTypes.bool,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  scrollToSection: PropTypes.func,
};

export default RecordIndex;
