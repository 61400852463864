import React from "react";
import PropTypes from "prop-types";
import { Stack, Box } from "@mui/material";
import DxAgeField from "./DxAgeField";
import DxGenderInput from "./DxGenderInput";
import DxPregnantProbabilityInput from "./DxPregnantProbabilityInput";
import SettingsForm from "./SettingsForm";

const PersonalInfoForm = ({ inputParams, setInputParamsHandler }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      aria-label="Personal info"
      sx={{ width: "100%", alignItems: "center", justifyContent: "center" }}
    >
      <Box width={120}>
        <DxAgeField
          selectedValue={inputParams?.edad || ""}
          setValue={setInputParamsHandler}
        />
      </Box>
      <DxGenderInput
        selectedValue={inputParams?.sexo || ""}
        setValue={setInputParamsHandler}
      />
      {inputParams?.sexo === "Femenino" && ( // Mostrar solo si el sexo es Femenino
        <Box>
          <DxPregnantProbabilityInput
            selectedValue={inputParams?.prob_embarazo || false}
            setValue={setInputParamsHandler}
          />
        </Box>
      )}
      <Box>
        <SettingsForm values={inputParams} setValue={setInputParamsHandler} />
      </Box>
    </Stack>
  );
};

PersonalInfoForm.propTypes = {
  inputParams: PropTypes.shape({
    edad: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sexo: PropTypes.string,
    prob_embarazo: PropTypes.bool,
  }).isRequired,
  setInputParamsHandler: PropTypes.func.isRequired,
};

export default PersonalInfoForm;
