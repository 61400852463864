import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import DxResultCard from "./DxResultCard";
import PropTypes from "prop-types";

const DxCardGrid = ({ columnNames, rowData }) => {
  const [dxResultDataList, setDxResultDataList] = useState([]);

  // Indices are stored in a useMemo to avoid recalculating on each render
  const indices = React.useMemo(() => {
    return {
      groupNameIndex: columnNames.indexOf("groupdx"),
      specificDxsIndex: columnNames.indexOf("all_group_dxs_and_score"),
      topScoreIndex: columnNames.indexOf("top_score"),
      topDxIndex: columnNames.indexOf("top_dx"),
    };
  }, [columnNames]);

  useEffect(() => {
    const parsedData = rowData[0]?.map((_, index) => {
      const jsonString = rowData[indices.specificDxsIndex][index].replace(
        /'/g,
        '"'
      );
      const parsedDxs = JSON.parse(jsonString);
      const specificDxs = Object.entries(parsedDxs).map(([key, value]) => ({
        name: key,
        score: value,
      }));

      return {
        groupdx: rowData[indices.groupNameIndex][index],
        top_score: rowData[indices.topScoreIndex][index],
        top_dx: rowData[indices.topDxIndex][index],
        specificDxs,
      };
    });

    setDxResultDataList(parsedData || []); // Safeguard against undefined parsedData
  }, [rowData, indices]);

  return (
    <Box
      sx={{
        maxHeight: "600px", // Limita la altura del contenedor
        overflowY: "auto",  // Habilita scroll vertical
        overflowX: "hidden", // Evita scroll horizontal
        border: "1px solid rgba(0, 0, 0, 0.2)", // Opcional: Borde para separar visualmente
        borderRadius: "8px", // Bordes redondeados
        padding: 2, // Espaciado interno
        backgroundColor: "background.default", // Fondo opcional para diferenciar
      }}
    >
      <Grid container spacing={2.5}>
        {dxResultDataList.map((dxResultData, index) => (
          <DxResultCard
            key={index}
            ranking={index + 1}
            dxResultData={dxResultData}
            specificDxsData={dxResultData.specificDxs}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default DxCardGrid;

DxCardGrid.propTypes = {
  columnNames: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
};
