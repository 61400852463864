import React from "react";
import PropTypes from "prop-types";
import { ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import { Female, Male } from "@mui/icons-material";

const genderParam = {
  name: "sexo",
  label: "Sexo",
  type: "select",
  options: [
    {
      value: "Masculino",
      label: <Male />,
    },
    {
      value: "Femenino",
      label: <Female />,
    },
  ],
};

const DxGenderInput = ({ selectedValue, setValue }) => {
  return (
    <Box>
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        onChange={(event, newValue) => {
          if (newValue !== null) {
            setValue(newValue, genderParam.name, genderParam.type);
          }
        }}
        sx={{
          display: "flex",
          borderRadius: "10px",
          overflow: "hidden",
          "& .MuiToggleButton-root": {
            borderRadius: "10px",
            fontSize: "0.9rem",
            fontWeight: "500",
            textTransform: "none",
            padding: "0.5rem 1.2rem",
            transition: "all 0.3s ease",
            color: "black",
            backgroundColor: "background.paper",
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "primary.main",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            },
            "&:hover": {
              backgroundColor: "primary.light",
              color: "white",
            },
          },
        }}
      >
        {genderParam.options.map((option) => (
          <ToggleButton key={option.value} value={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

DxGenderInput.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default DxGenderInput;
